
// ACTION TYPES //
export const types = {
    SET_MAPCONFIG: "SET_MAPCONFIG",
    SET_LAYERCONFIG: "SET_LAYERCONFIG",
    MAPCONFIG_FETCH: "MAPCONFIG_FETCH",
    MAPCONFIG_FETCH_ERROR: "MAPCONFIG_FETCH_ERROR",
    REFRESH_MAPTOKEN: "REFRESH_MAPTOKEN",
    REFRESH_MAPTOKEN_ERROR: "REFRESH_MAPTOKEN_ERROR",
    SET_MAPTOKEN: "SET_MAPTOKEN",
    SET_LOADED: "SET_LOADED",
    SET_DATA_LOADED: "SET_DATA_LOADED",
    SET_BASEMAPS: "SET_BASEMAPS",
    SET_REGIONS: "SET_REGIONS",
    LOAD_SPECIES: "LOAD_SPECIES",
    SET_MAP_REFERENCE: "SET_MAP_REFERENCE",
    SET_SPECIES_REFERENCE: "SET_SPECIES_REFERENCE",
    SET_SHOWFEATURES: "SET_SHOWFEATURES",
    SET_RIGHTPANEL_STATE: "SET_RIGHTPANEL_STATE"
};
//test comment
// REDUCERS //
export const initialState = {
    loaded: false,
    reference_data_loaded: false,
    mapconfig: null,
    tokeninfo: null,
    portal: null,
    groupID: null,
    layerConfig: {"global_data": {},"country_data": {}},
    basemapOptions: [{"id": "satellite", "title": "Satellite"},
                {"id": "osm", "title": "Open Street Map"},
               {"id": "streets-navigation-vector", "title": "Streets Navigation"},
               {"id": "terrain", "title": "Terrain"},
               {"id": "topo-vector", "title": "Topo Vector"}],
    countries: [{"label": "India","code": "IND", "energyoptions": ["ONSHORE","OFFSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Nepal","code": "NPL", "energyoptions": ["ONSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Thailand","code": "THA", "energyoptions": ["ONSHORE","OFFSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Vietnam","code": "VNM", "energyoptions": ["ONSHORE","OFFSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Kenya","code": "KEN", "energyoptions": ["ONSHORE","OFFSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Lao PDR","code": "LAO", "energyoptions": ["ONSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Uzbekistan","code": "UZB", "energyoptions": ["ONSHORE","SOLAR","PWDIST","PWTRAN"]},
                {"label": "Egypt","code": "EGY", "energyoptions": ["ONSHORE","OFFSHORE", "SOLAR","PWDIST","PWTRAN"]}],
    regions: {},
    energyTypes: [{"label": "Onshore Wind Energy", "code": "ONSHORE"},{"label": "Offshore Wind Energy", "code": "OFFSHORE"},{"label": "Solar Photovoltaic", "code": "SOLAR"},{"label": "Distribution Powerlines", "code": "PWDIST"},{"label": "Transmission Powerlines", "code": "PWTRAN"}],
    speciesReference: {},
    sitesLayers: [
        "avistep_sites_habitat_seagrass",
        "avistep_sites_habitat_mangrove",
        "avistep_sites_habitat_coralreef",
        "avistep_sites_iba_terrestrial",
        "avistep_sites_iba_marinecoastal",
        "avistep_sites_protected_terrestrial",
        "avistep_sites_protected_marinecoastal",
        "avistep_sites_seabird_colonies"
    ],
    showSitesLayers: false,
    showTool: true,
    rightPanelMinimized: false,
    error: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SHOWFEATURES:
            return {
                ...state,
                showTool: action.payload.showTool
            };
        case types.SET_MAPCONFIG:
            return {
                ...state,
                ...action.payload
            };
        case types.SET_MAPTOKEN:
            return {
                ...state,
                tokeninfo: action.payload
            };
        case types.SET_LAYERCONFIG:
            return {
                ...state,
                layerConfig: {
                    ...state.layerConfig,
                    global_data: {
                        ...state.layerConfig.global_data,
                        ...action.payload.global_data
                    },
                    country_data: {
                        ...state.layerConfig.country_data,
                        ...action.payload.country_data
                    }
                }
                
            };
        case types.SET_BASEMAPS:
            return {
                ...state,
                basemap: action.payload.basemaps
            };
        case types.SET_LOADED:
            return {
                ...state,
                loaded: true
            }
        case types.SET_RIGHTPANEL_STATE:
            return {
                ...state,
                rightPanelMinimized: action.payload.minimized
            }    
        case types.SET_DATA_LOADED:
            return {
                ...state,
                reference_data_loaded: true
            };
        case types.MAPCONFIG_FETCH_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case types.REFRESH_MAPTOKEN_ERROR:
            return {
                ...state,
                error: action.payload.error  
            };
        case types.SET_MAP_REFERENCE:
            return {
                ...state,
                regions: {
                    ...state.regions,
                    ...action.payload.regions
                },
                speciesReference: {
                    ...state.speciesReference,
                    ...action.payload.species_reference
                }  
            };
        case types.SET_SPECIES_REFERENCE:
            return {
                ...state,
                speciesReference: {
                    ...state.speciesReference,
                    [action.payload.country]: {...action.payload.species}
                }  
            };
        default:
            return state;
    }
};

// ACTIONS //
export const fetchMapConfig = () => ({ type: types.MAPCONFIG_FETCH });
export const setMapReferenceData = (regions) => ({ type: types.SET_MAP_REFERENCE, payload: { regions } });
export const setSpeciesReference = (country,species) => ({ type: types.SET_SPECIES_REFERENCE, payload: { country, species } });
export const setRightPanelState = (minimized) => ({ type: types.SET_RIGHTPANEL_STATE, payload: { minimized } });